@import '../../../assets/styles/sizes.scss';

.subscription {
  position: relative;

  .subscriptionHeadTitle {
    // margin-bottom: 60px;
    font-family: Montserrat;
    font-size: 32px;
    line-height: 38.4px;
    color: var(--black);
    margin-top: 20px;
    margin-bottom: 15px;

    @include media-md {
      margin-bottom: 30px;
    }

    @include media-smm {
      font-size: 24px;
      line-height: 100%;
    }
  }

  .subscriptionTabs {
    display: flex;

    .subscriptionTab {
      font-size: 16px;
      padding: 10px 0px;
      line-height: 20px;
      margin-right: 32px;
    }

    .activeTab {
      border-bottom: 2px var(--navy-blue) solid;
    }
  }

  .paymentModeSwitch {
    display: flex;
    justify-content: right;
    align-items: center;

    @include media-smm {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .paymentModes {
      width: 250px;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0.86px var(--port-gore) solid;
      border-radius: 108px;

      .paymentMode {
        padding: 0px 22px;
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        color: var(--black);
        cursor: pointer;
      }

      .activeMode {
        color: white;
        background-color: #2c3277;
        height: 100%;
        border-radius: 108px;
        display: flex;
        align-items: center;
      }
    }

    .annualDiscount {
      height: 25px;
      display: flex;
      align-items: center;
      padding: 5px;
      margin: 5px;
      font-size: 12px;
      line-height: 15px;
      color: white;
      background-color: #848ad1;
      border-radius: 4px;
    }
  }

  .subscriptionPlans {
    display: flex;
    // justify-content: space-between;
    // gap: 24px;
    margin-top: 35px;

    @include media-md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .planContainer {
      width: 100%;
      padding-top: 42px;
      flex-grow: 1;
      position: relative;

      @include media-md {
        width: 70%;
      }

      @include media-smm {
        width: 100%;
      }

      .popularBadge {
        position: absolute;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        background-color: #f7f7f7;
        border: 1px #ededed solid;
        border-radius: 12px 12px 0px 0px;

        .popularBadgeLabel {
          padding: 9px;
        }
      }

      .planBox {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        padding: 32px 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        padding-bottom: 80px;

        .planTitle {
          .mainTitle {
            padding: 0px;
            font-size: 32px;
            line-height: 38.4px;
            color: var(--alizarin-crimson);
          }

          .subTitle {
            padding: 0px;
            margin-top: 5px;
          }
        }

        .features {
          margin-top: 40px;

          .allFreeFeatures {
            font-size: 18px;
            line-height: 24.3px;
            font-weight: 700;
            text-align: left;
          }

          .checkedTypography {
            display: flex;
            align-items: center;

            .checkCircleIcon {
              color: var(--alizarin-crimson);
            }

            .featureContent {
              font-size: 18px;
              line-height: 20px;
              padding: 10px 5px;
              text-align: left;
            }
          }
        }

        .purchaseButton {
          width: 160px;
          height: 48px;
          position: absolute;
          bottom: 24px;
          right: 0px;
          left: 0px;
          margin: auto;
          padding: 0px;
          border: 1px var(--port-gore) solid;
          border-radius: 40px;
          background-color: white;

          .purchaseButtonLabel {
            font-size: 16px;
            line-height: 20px;
            color: var(--port-gore);
          }
        }

        .disabledButton {
          border-width: 0px;
          background-color: var(--gallery);

          .purchaseButtonLabel {
            color: var(--silver);
          }
        }
      }

      .proPlanBox {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        padding: 32px 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        padding-bottom: 80px;
        color: white;
        background-color: var(--port-gore);

        .planTitle {
          .mainTitle {
            padding: 0px;
            font-size: 32px;
            line-height: 38.4px;
            color: white;
          }

          .subTitle {
            padding: 0px;
            margin-top: 5px;
          }
        }

        .features {
          margin-top: 40px;

          .allFreeFeatures {
            font-size: 18px;
            line-height: 24.3px;
            font-weight: 700;
            text-align: left;
          }

          .checkedTypography {
            display: flex;
            align-items: center;

            // .checkCircleIcon {
            //   color: var(--alizarin-crimson);
            // }

            .featureContent {
              font-size: 18px;
              line-height: 20px;
              padding: 10px 5px;
              text-align: left;
            }
          }
        }

        .purchaseButton {
          width: 160px;
          height: 48px;
          position: absolute;
          bottom: 24px;
          right: 0px;
          left: 0px;
          margin: auto;
          padding: 0px;
          border: 1px var(--port-gore) solid;
          border-radius: 40px;
          background-color: white;

          .purchaseButtonLabel {
            font-size: 16px;
            line-height: 20px;
            color: var(--port-gore);
          }
        }

        .disabledButton {
          border-width: 0px;
          background-color: var(--gallery);

          .purchaseButtonLabel {
            color: var(--silver);
          }
        }
      }
    }
  }
}
