@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Regular'), url('../fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: normal;
  src: local('Montserrat Italic'), url('../fonts/Montserrat-Italic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Thin';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin'), url('../fonts/Montserrat-Thin.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Thin Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Thin Italic'), url('../fonts/Montserrat-ThinItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat ExtraLight';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraLight'), url('../fonts/Montserrat-ExtraLight.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat ExtraLight Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat ExtraLight Italic'),
    url('../fonts/Montserrat-ExtraLightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Light';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light'), url('../fonts/Montserrat-Light.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat Light Italic';
  font-style: normal;
  font-weight: normal;
  src: local('Montserrat Light Italic'), url('../fonts/Montserrat-LightItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), url('../fonts/Montserrat-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 500;
  src: local('Montserrat Medium Italic'),
    url('../fonts/Montserrat-MediumItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'), url('../fonts/Montserrat-SemiBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 600;
  src: local('Montserrat SemiBold Italic'),
    url('../fonts/Montserrat-SemiBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), url('../fonts/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 700;
  src: local('Montserrat Bold Italic'), url('../fonts/Montserrat-BoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: local('Montserrat ExtraBold'), url('../fonts/Montserrat-ExtraBold.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 800;
  src: local('Montserrat ExtraBold Italic'),
    url('../fonts/Montserrat-ExtraBoldItalic.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 900;
  src: local('Montserrat Black'), url('../fonts/Montserrat-Black.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: italic;
  font-weight: 900;
  src: local('Montserrat Black Italic'), url('../fonts/Montserrat-BlackItalic.woff') format('woff');
}
