@import '../../assets/styles/sizes.scss';

.burger {
  display: none;

  @include media-sm {
    display: block;
    position: relative;
    height: 30px;
    width: 30px;
    & > span {
      position: absolute;
      height: 2px;
      width: 24px;
      background-color: var(--catskill-white);
      display: block;
      top: 50%;
      left: 3px;
      transform: translateY(-50%);
      opacity: 1;
      transition: opacity 400ms;
    }
    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 24px;
      height: 2px;
      background-color: var(--catskill-white);
      transition: transform 400ms;
    }
    &:before {
      left: 3px;
      top: 7px;
    }
    &:after {
      left: 3px;
      bottom: 7px;
    }
  }
}

.burgerActive {
  & > span {
    opacity: 0;
    transition: opacity 400ms;
  }
  &::before,
  &::after {
    transition: transform 400ms;
  }
  &::before {
    transform: rotate(45deg);
    top: 13px;
  }
  &::after {
    transform: rotate(-45deg);
    top: 13px;
  }
}

.burgerContent {
  display: none;

  @include media-sm {
    position: absolute;
    background-color: var(--white);
    border: 1px solid var(--white);
    padding: 16px;
    border-radius: 4px;
    top: 64px;
    opacity: 0;
    transition: opacity 400ms;
  }
}

.active {
  @include media-sm {
    display: block;
    opacity: 1;
    transition: opacity 400ms;
    z-index: 3;
  }
}

.burgerContentItem {
  display: block;
  color: var(--mine-shaft);
  text-decoration: none;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
}
