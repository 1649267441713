@import '../../assets/styles/sizes.scss';
@import '../../assets/styles/typography';

.projectsHeadTitle {
  @include h1;
  padding-bottom: 10px;
  color: var(--black);

  @include media-smm {
    font-size: 18px;
  }
}

.noProjects {
  padding: 80px;

  @include media-md {
    padding: 40px 0;
  }

  .noProjectsItem {
    text-align: center;

    .noProjectsMedia {
      margin-bottom: 24px;

      img {
        max-width: 100%;
      }
    }

    .noProjectsText {
      margin-bottom: 32px;
      font-size: 18px;
      color: var(--boulder);

      p {
        margin-bottom: 8px;
        padding: 0;
        line-height: 135%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.noProjectsLink {
  @include a;
  @include button;
  border-radius: 30px;
  padding: 14px 12px;
  font-weight: 700;

  &:hover,
  &:active {
    opacity: 0.6;
  }

  &:focus {
    opacity: 0.8;
  }
}

.table {
  .tableTitle {
    display: flex;
    background-color: var(--alizarin-crimson);
    color: var(--white);
    padding: 10px;

    &:first-child {
      border-radius: 8px 8px 0 0;
      margin-bottom: 16px;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }

  .tableItem {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media-sm {
      font-size: 13px;
    }

    &:first-child {
      flex: 1;

      @include media-xl {
        flex: 1;
      }
    }
  }

  .specialColumn {
    @include media-sm {
      flex: 1 1;
      font-size: 13px;
    }
  }

  .tableBtn {
    @include media-sm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }

    @media (min-width: 1200px) and (max-width: 1400px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }

    @media (min-width: 768px) and (max-width: 831px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }
  }

  .tableBody {
    border-radius: 8px;
    margin-bottom: 16px;
  }
}

.tableBodyTop {
  display: flex;
  background-color: var(--gallery);
  border: 1px solid var(--silver);
  border-bottom-color: transparent;
  border-radius: 8px 8px 0 0;
  padding: 10px;
}

.tableBodyMiddle {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid var(--silver);
  border-top-color: transparent;
  border-bottom-color: transparent;
  cursor: pointer;

  &:hover {
    background-color: #f8f8f8;
  }

  .hiddenSm {
    @include media-sm {
      display: none;
    }
  }

  .button {
    border: 1px solid var(--port-gore);
    background-color: var(--white);
    font-weight: bold;
    padding: 8px 12px;
    border-radius: 8px;
    color: var(--port-gore);
    cursor: pointer;

    &:hover {
      background-color: var(--gallery);
    }

    + .button {
      margin-left: 5px;
    }

    @include media-sm {
      padding: 4px 12px;
    }
  }

  .hiddenSm {
    @include media-sm {
      display: none;
    }
  }
}

.tableBodyBottom {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid var(--silver);
  border-top-color: transparent;
  border-radius: 0 0 8px 8px;
  cursor: pointer;

  &:hover {
    background-color: #f8f8f8;
  }

  .hiddenSm {
    @include media-sm {
      display: none;
    }
  }

  .button {
    border: 1px solid var(--port-gore);
    background-color: var(--white);
    font-weight: bold;
    padding: 8px 12px;
    border-radius: 8px;
    color: var(--port-gore);
    cursor: pointer;

    &:hover {
      background-color: var(--gallery);
    }

    + .button {
      margin-left: 5px;
    }

    @include media-sm {
      padding: 4px 12px;
    }
  }

  .hiddenSm {
    @include media-sm {
      display: none;
    }
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  & > h1 {
    padding: 0;
  }
}

.tableBodyBottom.tableBodyTop {
  border: 1px solid var(--silver);
  border-radius: 8px;
}
