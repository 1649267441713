@import '../../assets/styles/sizes.scss';
@import '../../assets/styles/colors.scss';

.overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  // width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
}

.overlay-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
}

.overlay img {
  width: 100vw;
  height: auto;
}

.overlay p {
  color: white;
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  padding: 0;
}

.show {
  display: flex;
}

.hide {
  display: none;
}
