@import '../../assets/styles/sizes.scss';

.icon {
  font-size: 50px;
  margin-bottom: 5px;
}

.buttonItemElement {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 224px;
  width: 224px;
  max-height: 126px;
  height: 126px;
  overflow: hidden;

  @include media-smm {
    max-width: 100%;
    width: 100%;
    height: auto;
    max-height: none;
    aspect-ratio: 16/9;
  }

  video,
  img {
    width: 100%;
    height: 100%;
  }
}

.upload {
  position: relative;
  width: 100%;
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gallery);
  cursor: pointer;

  &:hover,
  &:active {
    .arrow {
      transform: scale(1.2);
    }
  }
}

.input {
  visibility: hidden;
  position: absolute;
  font-size: 1px;
  opacity: 0;
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--silver);
  border-radius: 32px;
  transition: all 0.3s ease-in;

  svg {
    transform: rotate(-90deg);
  }
}

.messageText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: var(--scorpion);
  text-align: center;
}

.error {
  color: var(--alizarin-crimson);
}

.uploadWrapper {
  background-color: var(--port-gore);
  border-radius: 8px;
  transition: background-color 200ms;

  padding: 12px;

  &:hover {
    background-color: lighten(#191c43, 8%);
    transition: background-color 200ms;
  }

  .upload {
    flex-direction: row;
    align-items: center;

    background-color: transparent;

    &:hover,
    &:active {
      .arrow {
        transform: scale(1);
      }
    }
  }

  .arrow {
    height: 11px;
    background-color: transparent;

    svg {
      transform: rotate(0);
    }
  }

  .text {
    color: white;
    font-weight: bold;
  }

  .messageText {
    color: var(--gallery);
    margin-left: 4px;
    padding: 0;
  }
}
