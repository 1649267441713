@import '../../../assets/styles/sizes.scss';
.modal {
  position: absolute !important;

  .cancelModal {
    position: relative;
    height: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    .cancelModalTitle {
      font-size: 36px;
      line-height: 20px;
      color: var(--black);
      font-weight: 500;
      letter-spacing: 0.25px;
    }

    .closeMark {
      position: absolute;
      top: 12px;
      right: 24px;
    }

    .content {
      padding: 12px 24px;

      .notation {
        text-align: justify;
        padding: 0px;
      }

      .notationList {
        .listItem {
          display: flex;
          align-items: center;
          padding: 8px;

          .content {
            padding: 0px 8px;
            text-align: justify;
          }
        }
      }

      .checkUnderstanding {
        display: flex;
        align-items: center;

        .checkbox {
          padding: 0px;
          color: #6a71c8;
        }

        .understandNotaion {
          padding: 0px 8px;
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 8px 24px;

      .backButton {
        width: 231px;
        height: 38px;
        border-radius: 40px;
        background-color: var(--port-gore);

        .buttonLabel {
          color: white;
        }
      }

      .confirmButton {
        width: 231px;
        height: 38px;
        padding: 0px;
        border: 1px var(--port-gore) solid;
        border-radius: 40px;
        background-color: white;

        .buttonLabel {
          color: var(--port-gore);
        }
      }

      .buttonLabel {
        padding: 0px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
      }

      .disabledButton {
        border-width: 0px;
        background-color: var(--gallery);

        .buttonLabel {
          color: var(--silver);
        }
      }
    }
  }
}
