@import '../../assets/styles/sizes.scss';

.sidebar {
  // width: 236px;
  color: var(--port-gore);
  padding: 24px 0;
  overflow: hidden;
  border-right: 1px solid var(--gray);
  background-color: var(--white);

  @include media-xl {
    width: 184px;
  }

  @include media-sm {
    display: none;
  }

  .sidebarHeaderOpen {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 13px 0 24px;

    .menuIconButton {
      border: none;
      background: none;
      cursor: pointer;
      padding-left: 30px;

      @include media-xl {
        padding: 5px;
      }
    }
  }

  .sidebarHeaderClosed {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .menuIconButton {
      border: none;
      background: none;
      cursor: pointer;
      height: 20px;

      @include media-xl {
        padding: 5px;
      }
    }
  }

  .logoOnly {
    width: 50px;
    height: auto;
    padding: 0;
  }

  .profileInfo {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 12px 24px;
    margin-top: 20px;
    margin-bottom: 16px;
    border-top: 1px solid var(--gallery);
    border-bottom: 1px solid var(--gallery);

    .profileInfoImg {
      background: #d62223;
      padding: 8px;
      border-radius: 6px;
      color: white;
      font-size: 10px;
    }

    .profileInfoTexts {
      display: flex;
      // margin-left: 12px;
      // width: 100%;

      .profileInfoUsername {
        font-size: 14px;
      }

      .profileInfoEmail {
        font-size: 12px;
      }
    }

    .profileInfoSubscription {
      display: block;
      margin-left: 12px;
      width: 100%;

      .profileInfoTrial {
        font-size: 14px;
        margin-bottom: 20px;
        margin-top: 10px;
        color: red;
      }

      .profileInfoLink {
        .profileInfoLink {
          font-size: 12px;
        }
      }
    }
  }

  .listWrapper {
    font-size: 14px;
    width: 100%;

    .list {
      display: inline-flex;
      flex-direction: column;
      line-height: 24px;
      width: 100%;
    }

    .item {
      color: var(--port-gore);
      cursor: pointer;
      display: flex;
      align-items: center;
      padding: 10px 26px;
      transition: all 150ms ease;
      width: 100%;

      img {
        width: 17px;
        max-width: 25px;
      }

      span {
        padding-left: 18px;
      }

      &:hover {
        background-color: var(--catskill-white);
      }
    }

    .centered {
      justify-content: center;
    }
  }

  .logout {
    display: flex;
    align-items: center;
    background-color: var(--white);
    border: none;
    cursor: pointer;
    word-break: break-all;
    padding: 10px;
    margin: 0;
    justify-content: space-between;
    width: 100%;

    &:hover {
      color: var(--alizarin-crimson);

      & .logoutIcon path {
        fill: var(--alizarin-crimson);
      }
    }

    @include media-sm {
      background-color: var(--alizarin-crimson);

      &:hover .logoutIcon path {
        fill: var(--white);
      }
    }
  }

  .email {
    display: flex;
    align-items: center;
    flex-direction: column;

    p {
      margin: 0;
      padding: 0;
      line-height: normal;
    }

    @include media-sm {
      display: none;
    }
  }

  .emailClosed {
    display: none;
    justify-content: center;
  }

  .logoutIcon {
    margin-left: 8px;

    @include media-sm {
      width: 24px;
      height: 24px;
      margin-left: 0;

      & path {
        fill: var(--white);
      }
    }
  }

  .logoutIconClosed {
    margin-left: 0;
  }
}

.open {
  width: 236px;
}

.closed {
  width: 100px;
}
