@import '../../assets/styles/sizes.scss';
@import '../../assets/styles/typography';

.pageTitle {
  @include h1;
  padding-bottom: 10px;
  color: var(--black);

  @include media-smm {
    font-size: 18px;
  }
}

.pageDescription {
  padding: 0 0 10px;
}

.subTitles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.table {
  .tableTitle {
    display: flex;
    background-color: var(--alizarin-crimson);
    color: var(--white);
    padding: 10px;

    &:first-child {
      border-radius: 8px 8px 0 0;
      margin-bottom: 16px;
    }

    &:last-child {
      border-radius: 0 0 8px 8px;
    }
  }

  .tableItem {
    flex: 1;

    @include media-sm {
      font-size: 13px;
      text-align: center;
    }

    input[type='text'] {
      width: calc(100% - 50px);
      font-size: 0.9em;
    }
  }

  .tableBtn {
    flex: 1;
    display: flex;
    gap: 5px;

    @include media-sm {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }

    @media (min-width: 768px) and (max-width: 831px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
    }
  }

  .columnType {
    flex: 0.33;
  }

  .columnPlaybackUrl {
    flex: 1.33;
  }

  .tableBody {
    border-radius: 8px;
    margin-bottom: 16px;
  }
}

.tableBodyTop {
  display: flex;
  background-color: var(--gallery);
  border: 1px solid var(--silver);
  border-radius: 8px 8px 8px 8px;
  padding: 10px;
  align-items: center;
}

.navbar {
  & > h1 {
    padding: 0;
  }
}
