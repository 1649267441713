@import '../../../assets/styles/sizes.scss';

.modal {
  position: absolute;

  .cancelModal {
    position: relative;
    height: 100%;
    padding: 32px 32px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    .cancelModalTitle {
      font-size: 36px;
      line-height: 20px;
      color: var(--black);
      font-weight: 500;
      letter-spacing: 0.25px;
    }

    .closeMark {
      position: absolute;
      top: 12px;
      right: 24px;
    }

    .content {
      padding: 12px 24px;

      .notation {
        text-align: center;
        padding: 0px 24px;
        font-size: 13px;
        line-height: 20px;
      }

      .sadContent {
        .main {
          padding: 24px 8px 8px;
          font-size: 16px;
          font-weight: 700;
          line-height: 20px;
        }

        .sub {
          padding: 8px;
          font-size: 13px;
          font-weight: 400;
          line-height: 20px;
          text-align: justify;
        }
      }

      .textTitle {
        padding: 16px 0px 0px 8px;
        font-size: 13px;
        font-weight: 500;
        line-height: 20px;
        color: var(--navy-blue);
      }

      .textField {
        width: 100%;
        border: 2px var(--gallery) solid;
        border-radius: 6px;
        box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.07);
      }

      .bottom {
        padding: 32px 0px 4px;
        font-size: 11px;
        font-weight: 400;
        line-height: 20px;
        font-style: italic;
        color: var(--navy-blue);

        .link {
          font-weight: 700;
          text-decoration: underline;
        }
      }
    }

    .buttons {
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 8px 24px;

      .backButton {
        width: 231px;
        height: 38px;
        border-radius: 40px;
        background-color: var(--port-gore);

        .buttonLabel {
          color: white;
        }
      }

      .confirmButton {
        width: 231px;
        height: 38px;
        padding: 0px;
        border: 1px var(--port-gore) solid;
        border-radius: 40px;
        background-color: white;

        .buttonLabel {
          color: var(--port-gore);
        }
      }

      .buttonLabel {
        padding: 0px;
        font-size: 16px;
        line-height: 22px;
        font-weight: 700;
      }

      .disabledButton {
        border-width: 0px;
        background-color: var(--gallery);

        .buttonLabel {
          color: var(--silver);
        }
      }
    }
  }
}
