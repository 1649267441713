@import '../../assets/styles/sizes.scss';

.container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center;

  & div {
    margin-top: 16px;
  }
}

.spinner {
  width: 50px;
  height: 50px;
}
