@import '../../assets/styles/typography.scss';
@import '../../assets/styles/colors.scss';
@import '../../assets/styles/sizes.scss';

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  & > h1 {
    padding: 0;
  }

  @include media-sm {
    display: block;
  }
}

.projectsHeadTitle {
  @include h1;
  padding-bottom: 10px;
  color: var(--black);

  @include media-smm {
    font-size: 18px;
  }
}

.searchBar {
  border: 1px solid var(--silver);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 2px 8px 2px 8px;

  input {
    border: none;
  }

  @include media-sm {
    width: fit-content;
  }
}

.integrationTabs {
  display: flex;
  padding-bottom: 10px;

  .integrationTab {
    font-size: 16px;
    padding: 10px 0px;
    line-height: 20px;
    margin-right: 32px;
    cursor: pointer;
  }

  .activeTab {
    border-bottom: 2px var(--navy-blue) solid;
  }
}

.cloudStorage {
  padding-top: 20px;
  display: flex;

  .accordion {
    width: 280px;
    min-height: 250px;
    margin: 8px;
    transition: all 0.2s ease;
    border: solid 1px var(--light-silver);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .summary {
    display: flex;

    .summaryTitle {
      padding-left: 20px;
    }
  }

  .summaryDescription {
    font-size: 12px;
    color: var(--gray);
  }

  .summaryButton {
    border-radius: 40px;
  }
}

.socialMedia {
  padding-top: 10px;
  display: flex;
  gap: 50px;

  .card {
    display: block;
    border: solid 1px var(--light-silver);
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }

  .summary {
    display: flex;
    flex-direction: column;
    align-items: center;

    .summaryTitle {
      padding: 10px 0 10px 0;
    }
  }

  .logo {
    padding-top: 10px;
  }

  .centeredContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .summaryButton {
    border-radius: 40px;
  }
}
