@import '../../assets/styles/sizes.scss';

.inputViewMode,
.inputEditMode {
  width: 90%;
  font-size: 24px;
  line-height: 36px;
  font-weight: 600;
  letter-spacing: 0.01em;
  color: var(--tundors);
  border-radius: 4px;
  outline: none;
  border: 2px solid transparent;
  padding-left: 5px;
  caret-color: transparent;
  text-align: justify;
  cursor: text;
}

.hide {
  position: absolute;
  opacity: 0;
  z-index: -100;
  white-space: pre;
}

.inputEditMode {
  border: 2px solid var(--port-gore);
  caret-color: auto;
}

.editIcon {
  width: 20px;
  height: 20px;
  background-color: var(--port-gore);
  border-radius: 50%;
  padding: 4px;
  cursor: pointer;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.videoTitle {
  font-size: 100%;
  @include media-sm {
    font-size: 90%;
    max-width: fit-content;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
