@import '../../../assets/styles/sizes.scss';

.modal {
  .closeMark {
    display: flex;
    float: right;
    padding: 10px;
    margin-top: 20px;
    margin-right: 20px;

    @include media-smm {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
  .paymentList {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.5px;
    padding: 0px;
    margin-bottom: 10px;
    margin-left: 3px;
  }
  .gridContainer {
    padding: 5px;

    .orderSummary {
      .planTitle {
        padding: 0px;
        font-size: 32px;
        font-weight: 700;
        line-height: 38.4px;
        text-align: center;

        @include media-smm {
          font-size: 20px;
        }
      }

      .planDescription {
        padding: 8px 0px 0px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        text-align: center;
      }

      .orderTitle {
        padding: 35px 0px 0px;
        font-size: 32px;
        font-weight: 500;
        line-height: 20px;
        text-align: center;
      }

      .paymentModeSwitch {
        padding-top: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        .paymentModes {
          width: 250px;
          height: 50px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: 0.86px var(--port-gore) solid;
          border-radius: 108px;

          @include media-smm {
            width: 200px;
            height: 45px;
          }

          .paymentMode {
            padding: 0px 22px;
            font-size: 20px;
            line-height: 25px;
            font-weight: 400;
            color: var(--black);
            cursor: pointer;

            @include media-smm {
              font-size: 16px;
            }
          }

          .activeMode {
            color: white;
            background-color: #2c3277;
            height: 100%;
            border-radius: 108px;
            display: flex;
            align-items: center;
          }
        }

        .annualDiscount {
          height: 25px;
          display: flex;
          align-items: center;
          text-align: center;
          padding: 5px;
          margin: 6px;
          font-size: 12px;
          line-height: 15px;
          color: white;
          background-color: #848ad1;
          border-radius: 4px;
        }
      }

      .invoice {
        padding: 10px;
        .priceTable {
          margin-top: 33px;

          .tableHeader {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            background-color: #d9d9d9;
            font-weight: bold;

            .tableItem {
              padding: 14px;
              font-size: 16px;
              line-height: 20px;
              color: var(--black);
            }
          }

          .tableBody {
            margin-top: 17px;
            padding: 0px 12px 0px 12px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

            .productDescription {
              display: flex;
              justify-content: space-between;
              align-items: center;

              .closeIcon {
                display: flex;
                align-items: center;
              }
            }
            .productContent {
              display: flex;
              flex-direction: column;
              padding: 5px 0px 5px 14px;

              .productTop {
                padding: 0px;
                font-size: 20px;
                line-height: 20px;
                font-weight: 500;
              }

              .productBottom {
                padding: 3px 0px 3px 0px;
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
          .price {
            font-size: 20px;
            font-weight: 500;
            line-height: 20px;
          }

          .horizonLine {
            height: 28px;
            opacity: 0.1;
            border-bottom: 1px #001c80 solid;
          }
        }

        .totalPrice {
          .totalPriceTop {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 21px 12px 0px 9px;

            .tableItem {
              font-size: 20px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0.25px;
              word-wrap: break-word;
              padding: 0px;
            }
          }
          .totalPriceBottom {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 7px 9px 0px 9px;

            .renewInfo {
              font-size: 14px;
              font-weight: 400;
              line-height: 17.5px;
              padding: 0px;

              .renewDate {
                font-weight: 500;
              }
            }

            .totalPriceUnit {
              font-size: 20px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0.25px;
              padding: 0px;
            }

            .horizonLine {
              height: 24px;
              opacity: 0.1;
              border-bottom: 1px #001c80 solid;
            }
          }
        }
        .email {
          padding: 15px 12px 5px 5px;

          .inputLabel {
            font-size: 14px;
            font-weight: 400;
            line-height: 17.5px;
            padding: 0px;
          }

          .emailInput {
            width: 100%;
            margin-top: 8px;
            padding: 2px 0px 1px 6.2px;
            color: var(--black);
            font-size: 16px;
            font-family: Roboto;
            font-weight: 500;
            line-height: 20px;
            border-radius: 4px;
            border: 1px #a7a7a7 solid;
          }
        }
      }
    }

    .paymentDetails {
      .paymentDetailTitle {
        text-align: center;
        color: black;
        font-size: 32px;
        font-weight: 500;
        line-height: 32px;
        letter-spacing: 0.25px;
        word-wrap: break-word;
      }

      .paymentElement {
        padding: 0px 40px 0px 40px;

        .submitButton {
          width: 100%;
          margin-top: 41px;
          background: #191c43;
          border-radius: 40px;

          .submitButtonLabel {
            color: white;
            font-size: 16px;
            font-weight: 700;
            line-height: 22.16px;
            word-wrap: break-word;
          }
        }
      }

      .faq {
        // margin-top: 90px;
        margin: 5px;
        .faqLabel {
          color: var(--light-gray);
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0px 16px 0px 0px;
          font-size: 13px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0.25px;
          word-wrap: break-word;
        }

        .faqContent {
          margin-top: 24px;
          .faqQuestion {
            color: var(--port-gore);
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.25px;
            word-wrap: break-word;
          }

          .faqAnswer {
            color: var(--light-gray);
            font-size: 13px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0.25px;
            word-wrap: break-word;
          }
        }
      }
    }
  }
}

// .modal {
//   position: absolute !important;
//   top: 50px !important;

//   .modalContainer {
//     position: relative;
//     display: flex;
//     border-radius: 34px;

//     .closeMark {
//       position: absolute;
//       top: 27px;
//       right: 34px;
//     }

//     .orderSummary {
//       width: 45%;
//       padding: 24px 21px 130px 24px;
//       box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.25);

//       .planTitle {
//         padding: 0px;
//         font-size: 32px;
//         font-weight: 700;
//         line-height: 38.4px;
//         text-align: center;
//       }

//       .planDescription {
//         padding: 8px 0px 0px;
//         font-size: 16px;
//         font-weight: 400;
//         line-height: 20px;
//         text-align: center;
//       }

//       .orderTitle {
//         padding: 35px 0px 0px;
//         font-size: 32px;
//         font-weight: 500;
//         line-height: 20px;
//         text-align: center;
//       }

//       .paymentModeSwitch {
//         padding-top: 35px;
//         display: flex;
//         align-items: center;
//         justify-content: center;

//         .paymentModes {
//           width: 250px;
//           height: 50px;
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           border: 0.86px var(--port-gore) solid;
//           border-radius: 108px;

//           .paymentMode {
//             padding: 0px 22px;
//             font-size: 20px;
//             line-height: 25px;
//             font-weight: 400;
//             color: var(--black);
//             cursor: pointer;
//           }

//           .activeMode {
//             color: white;
//             background-color: #2c3277;
//             height: 100%;
//             border-radius: 108px;
//             display: flex;
//             align-items: center;
//           }
//         }

//         .annualDiscount {
//           height: 25px;
//           display: flex;
//           align-items: center;
//           text-align: center;
//           padding: 5px;
//           margin: 6px;
//           font-size: 12px;
//           line-height: 15px;
//           color: white;
//           background-color: #848ad1;
//           border-radius: 4px;
//         }
//       }

//       .invoice {
//         .priceTable {
//           margin-top: 33px;
//           .tableHeader {
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             align-items: center;
//             background-color: #d9d9d9;

//             .tableItem {
//               padding: 14px;
//               font-size: 16px;
//               line-height: 20px;
//               color: var(--black);
//             }
//           }

//           .tableBody {
//             margin-top: 17px;
//             padding: 0px 12px 0px 12px;
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             align-items: center;
//             box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

//             .productDescription {
//               display: flex;
//               justify-content: space-between;
//               align-items: center;

//               .closeIcon {
//                 display: flex;
//                 align-items: center;
//               }

//               .productContent {
//                 display: flex;
//                 flex-direction: column;
//                 padding: 5px 0px 5px 14px;

//                 .productTop {
//                   padding: 0px;
//                   font-size: 20px;
//                   line-height: 20px;
//                   font-weight: 500;
//                 }

//                 .productBottom {
//                   padding: 3px 0px 3px 0px;
//                   font-size: 14px;
//                   line-height: 20px;
//                 }
//               }
//             }

//             .price {
//               font-size: 20px;
//               font-weight: 500;
//               line-height: 20px;
//             }
//           }

//           .horizonLine {
//             height: 28px;
//             opacity: 0.1;
//             border-bottom: 1px #001c80 solid;
//           }
//         }

//         .totalPrice {
//           .totalPriceTop {
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             align-items: center;
//             padding: 21px 12px 0px 9px;

//             .tableItem {
//               font-size: 20px;
//               font-weight: 500;
//               line-height: 20px;
//               letter-spacing: 0.25px;
//               word-wrap: break-word;
//               padding: 0px;
//             }
//           }

//           .totalPriceBottom {
//             display: flex;
//             flex-direction: row;
//             justify-content: space-between;
//             align-items: center;
//             padding: 7px 9px 0px 9px;

//             .renewInfo {
//               font-size: 14px;
//               font-weight: 400;
//               line-height: 17.5px;
//               padding: 0px;

//               .renewDate {
//                 font-weight: 500;
//               }
//             }

//             .totalPriceUnit {
//               font-size: 20px;
//               font-weight: 500;
//               line-height: 20px;
//               letter-spacing: 0.25px;
//               padding: 0px;
//             }
//           }

//           .horizonLine {
//             height: 24px;
//             opacity: 0.1;
//             border-bottom: 1px #001c80 solid;
//           }
//         }

//         .email {
//           padding: 21px 8px 0px 0px;

//           .inputLabel {
//             font-size: 14px;
//             font-weight: 400;
//             line-height: 17.5px;
//             padding: 0px;
//           }

//           .emailInput {
//             width: 100%;
//             margin-top: 12px;
//             padding: 2px 0px 1px 6.2px;
//             color: var(--black);
//             font-size: 16px;
//             font-family: Roboto;
//             font-weight: 500;
//             line-height: 20px;
//             border-radius: 4px;
//             border: 1px #a7a7a7 solid;
//           }
//         }
//       }
//     }

//     .paymentDetail {
//       width: 54%;
//       padding: 90px 50px 24px 50px;

//       .paymentDetailTitle {
//         text-align: center;
//         padding: 0px 0px 37px 0px;
//         color: var(--black);
//         font-size: 32px;
//         font-weight: 500;
//         line-height: 32px;
//         letter-spacing: 0.25px;
//         word-wrap: break-word;
//       }

//       .paymentElement {
//         padding: 0px 40px 0px 40px;

//         .submitButton {
//           width: 100%;
//           margin-top: 41px;
//           background: #191c43;
//           border-radius: 40px;

//           .submitButtonLabel {
//             color: white;
//             font-size: 16px;
//             font-weight: 700;
//             line-height: 22.16px;
//             word-wrap: break-word;
//           }
//         }
//       }

//       .faq {
//         // margin-top: 90px;

//         .faqLabel {
//           color: var(--light-gray);
//           display: flex;
//           justify-content: center;
//           align-items: center;
//           padding: 0px 16px 0px 0px;
//           font-size: 13px;
//           font-weight: 500;
//           line-height: 20px;
//           letter-spacing: 0.25px;
//           word-wrap: break-word;
//         }

//         .faqContent {
//           margin-top: 24px;
//           .faqQuestion {
//             color: var(--port-gore);
//             font-size: 13px;
//             font-weight: 500;
//             line-height: 20px;
//             letter-spacing: 0.25px;
//             word-wrap: break-word;
//           }

//           .faqAnswer {
//             color: var(--light-gray);
//             font-size: 13px;
//             font-weight: 500;
//             line-height: 20px;
//             letter-spacing: 0.25px;
//             word-wrap: break-word;
//           }
//         }
//       }
//     }
//   }
// }
