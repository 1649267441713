@import '../../../assets/styles/sizes.scss';
@import '../../../assets/styles/typography.scss';

.subscription {
  // position: relative;

  .subscriptionHeadTitle {
    @include h1;
    padding-bottom: 10px;
    color: var(--black);

    @include media-smm {
      font-size: 20px;
    }
  }

  .subscriptionTabs {
    display: flex;

    .subscriptionTab {
      font-size: 16px;
      padding: 10px 0px;
      line-height: 20px;
      margin-right: 32px;
    }

    .activeTab {
      border-bottom: 2px var(--navy-blue) solid;
    }
  }

  .cancelButton {
    // float: right;
    border: 1px var(--light-gray) solid;
    border-radius: 40px;
    background-color: white !important;
    color: var(--port-gore);
    font-size: 16px;
  }

  .curPlanPanels {
    // display: flex;
    // justify-content: space-between;
    // gap: 10px;
    margin-top: 10px;

    .planInfoPanel {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 60px 24px;
      border: 1px var(--light-silver) solid;
      box-shadow: 0 4px 4px 0px rgba(0, 0, 0, 0.25);

      .panelTitle {
        position: absolute;
        top: 0px;
        left: 24px;
        font-size: 18px;
        line-height: 24.3px;
        font-weight: 600;
        color: var(--light-gray);

        @include media-smm {
          font-size: 14px;
        }
      }

      .panelContent {
        font-size: 34px;
        line-height: 43.2px;
        font-weight: 700;
        color: var(--port-gore);

        @include media-smm {
          font-size: 20px;
        }
      }
    }
  }

  .tableName {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;
    color: var(--port-gore);
  }

  .orderHistoryList {
    margin-top: 40px;

    .table {
      width: 100%;
      margin-bottom: 100px;

      .tableTitle {
        display: flex;
        padding: 10px;
        color: var(--white);
        background-color: var(--alizarin-crimson);

        .tableItem {
          font-size: 18px;
          // line-height: 24.3px;
          font-weight: bold;
          text-align: left;

          @include media-smm {
            font-size: 12px;
          }
        }
      }

      .tableBody {
        width: 100%;

        .tableRow {
          display: flex;
          padding: 10px 5px 10px 10px;
          border: 1px var(--gallery) solid;

          .tableItem {
            font-size: 18px;
            line-height: 24.3px;
            font-weight: 700;

            .itemContent {
              display: flex;
              font-size: 18px;
              line-height: 24.3px;
              font-weight: 700;
              padding: 0px;
            }
          }

          .containAction {
            display: flex;
            justify-content: space-between;

            .expandLink {
              cursor: pointer;
            }
          }
        }

        .tableSubRow {
          display: flex;
          padding: 10px 10px 10px 10px;
          border: 1px var(--gallery) solid;
          background-color: var(--gallery);

          .tableItem {
            display: flex;
            flex-direction: column;
            font-size: 15px;
            line-height: 20px;
            font-weight: 400;

            .itemContent {
              padding: 3px;
            }
          }

          .pdfDownload {
            flex-direction: row;
            align-items: center;
            justify-content: end;
            cursor: pointer;
          }
        }
      }

      .tableItem {
        flex: 1;
      }
    }
  }

  // .table {
  //   .tableTitle {
  //     display: flex;
  //     background-color: var(--alizarin-crimson);
  //     color: var(--white);
  //     padding: 10px;

  //     &:first-child {
  //       border-radius: 8px 8px 0 0;
  //       margin-bottom: 16px;
  //     }

  //     &:last-child {
  //       border-radius: 0 0 8px 8px;
  //     }
  //   }

  //   .tableItem {
  //     flex: 1;
  //     overflow: hidden;
  //     text-overflow: ellipsis;

  //     @include media-sm {
  //       font-size: 13px;
  //       text-align: center;
  //     }

  //     &:first-child {
  //       flex: 5;

  //       @include media-xl {
  //         flex: 1;
  //       }
  //     }
  //   }

  //   .tableBody {
  //     border-radius: 8px;
  //     margin-bottom: 16px;
  //   }
  // }

  // .tableBodyTop {
  //   display: flex;
  //   background-color: var(--gallery);
  //   border: 1px solid var(--silver);
  //   border-bottom-color: transparent;
  //   border-radius: 8px 8px 0 0;
  //   padding: 10px;
  // }

  // .tableBodyMiddle {
  //   display: flex;
  //   align-items: center;
  //   padding: 10px;
  //   border: 1px solid var(--silver);
  //   border-top-color: transparent;
  //   border-bottom-color: transparent;
  //   cursor: pointer;

  //   &:hover {
  //     background-color: #f8f8f8;
  //   }
  //   .hiddenSm {
  //     @include media-sm {
  //       display: none;
  //     }
  //   }

  //   .button {
  //     border: 1px solid var(--port-gore);
  //     background-color: var(--white);
  //     font-weight: bold;
  //     padding: 8px 12px;
  //     border-radius: 8px;
  //     color: var(--port-gore);
  //     cursor: pointer;

  //     &:hover {
  //       background-color: var(--gallery);
  //     }

  //     + .button {
  //       margin-left: 5px;
  //     }

  //     @include media-sm {
  //       padding: 4px 12px;
  //     }
  //   }

  //   .hiddenSm {
  //     @include media-sm {
  //       display: none;
  //     }
  //   }
  // }

  // .tableBodyBottom {
  //   display: flex;
  //   align-items: center;
  //   padding: 10px;
  //   border: 1px solid var(--silver);
  //   border-top-color: transparent;
  //   border-radius: 0 0 8px 8px;
  //   cursor: pointer;

  //   &:hover {
  //     background-color: #f8f8f8;
  //   }
  //   .hiddenSm {
  //     @include media-sm {
  //       display: none;
  //     }
  //   }

  //   .button {
  //     border: 1px solid var(--port-gore);
  //     background-color: var(--white);
  //     font-weight: bold;
  //     padding: 8px 12px;
  //     border-radius: 8px;
  //     color: var(--port-gore);
  //     cursor: pointer;

  //     &:hover {
  //       background-color: var(--gallery);
  //     }

  //     + .button {
  //       margin-left: 5px;
  //     }

  //     @include media-sm {
  //       padding: 4px 12px;
  //     }
  //   }

  //   .hiddenSm {
  //     @include media-sm {
  //       display: none;
  //     }
  //   }
  // }

  .paymentModeSwitch {
    display: flex;
    align-items: center;
    float: right;
    position: absolute;
    right: 5px;
    top: 100px;

    .paymentModes {
      width: 250px;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 0.86px var(--port-gore) solid;
      border-radius: 108px;

      .paymentMode {
        padding: 0px 22px;
        font-size: 20px;
        line-height: 25px;
        font-weight: 400;
        color: var(--black);
        cursor: pointer;
      }

      .activeMode {
        color: white;
        background-color: #2c3277;
        height: 100%;
        border-radius: 108px;
        display: flex;
        align-items: center;
      }
    }

    .annualDiscount {
      height: 25px;
      display: flex;
      align-items: center;
      padding: 5px;
      margin: 5px;
      font-size: 12px;
      line-height: 15px;
      color: white;
      background-color: #848ad1;
      border-radius: 4px;
    }
  }

  .subscriptionPlans {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-top: 35px;

    @include media-md {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .planContainer {
      width: 100%;
      padding-top: 42px;
      flex-grow: 1;
      position: relative;

      @include media-md {
        width: 70%;
      }

      @include media-smm {
        width: 100%;
      }

      .popularBadge {
        position: absolute;
        top: 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        background-color: #f7f7f7;
        border: 1px #ededed solid;
        border-radius: 12px 12px 0px 0px;

        .popularBadgeLabel {
          padding: 9px;
        }
      }

      .planBox {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        padding: 32px 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        padding-bottom: 80px;

        .planTitle {
          .mainTitle {
            padding: 0px;
            font-size: 32px;
            line-height: 38.4px;
            color: var(--alizarin-crimson);
          }

          .subTitle {
            padding: 0px;
            margin-top: 5px;
          }
        }

        .features {
          margin-top: 40px;

          .allFreeFeatures {
            font-size: 18px;
            line-height: 24.3px;
            font-weight: 700;
            text-align: left;
          }

          .checkedTypography {
            display: flex;
            align-items: center;

            .checkCircleIcon {
              color: var(--alizarin-crimson);
            }

            .featureContent {
              font-size: 18px;
              line-height: 20px;
              padding: 10px 5px;
              text-align: left;
            }
          }
        }

        .purchaseButton {
          width: 160px;
          height: 48px;
          position: absolute;
          bottom: 24px;
          right: 0px;
          left: 0px;
          margin: auto;
          padding: 0px;
          border: 1px var(--port-gore) solid;
          border-radius: 40px;
          background-color: white;

          .purchaseButtonLabel {
            font-size: 16px;
            line-height: 20px;
            color: var(--port-gore);
          }
        }
      }

      .proPlanBox {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        padding: 32px 16px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        padding-bottom: 80px;
        color: white;
        background-color: var(--port-gore);

        .planTitle {
          .mainTitle {
            padding: 0px;
            font-size: 32px;
            line-height: 38.4px;
            color: white;
          }

          .subTitle {
            padding: 0px;
            margin-top: 5px;
          }
        }

        .features {
          margin-top: 40px;

          .allFreeFeatures {
            font-size: 18px;
            line-height: 24.3px;
            font-weight: 700;
            text-align: left;
          }

          .checkedTypography {
            display: flex;
            align-items: center;

            // .checkCircleIcon {
            //   color: var(--alizarin-crimson);
            // }

            .featureContent {
              font-size: 18px;
              line-height: 20px;
              padding: 10px 5px;
              text-align: left;
            }
          }
        }

        .purchaseButton {
          width: 160px;
          height: 48px;
          position: absolute;
          bottom: 24px;
          right: 0px;
          left: 0px;
          margin: auto;
          padding: 0px;
          border: 1px var(--port-gore) solid;
          border-radius: 40px;
          background-color: white;

          .purchaseButtonLabel {
            font-size: 16px;
            line-height: 20px;
            color: var(--port-gore);
          }
        }
      }
    }
  }
}
