@import '../../../assets/styles/sizes.scss';

.modal {
  .modalContainer {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 34px;

    .closeMark {
      position: absolute;
      top: 27px;
      right: 34px;
    }

    .pdfDownload {
      position: absolute;
      top: 73px;
      left: 62px;
      display: flex;
      justify-content: center;
      align-items: center;

      .pdfDownloadLabel {
        padding: 0px;
        padding-left: 21px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 50%;

      .circledCheck {
        width: 60px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 60px;
        background-color: #1aa703;
      }

      .title {
        padding: 0px;
        padding-top: 38px;
        color: var(--black);
        font-size: 32px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.25px;
        word-wrap: break-word;
      }

      .description {
        text-align: center;
        padding: 32px 55px 44px;
        // Congratulations! Your payment was successful. Check your inbox for your confirmation email.
        color: var(--black);
        font-size: 18px;
        font-weight: 400;
        line-height: 24.3px;
        word-wrap: break-word;
        border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
      }

      .email {
        padding: 0px;
        padding-top: 27px;
        // samsmith@mail.com
        color: var(--black);
        font-size: 32px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.25px;
        word-wrap: break-word;
      }
    }
  }
}
