@import '../../assets/styles/sizes.scss';

.brandKitHeadTitle {
  margin-bottom: 60px;
  color: var(--black);

  @include media-md {
    margin-bottom: 30px;
  }

  @include media-smm {
    font-size: 24px;
    line-height: 100%;
  }
}

.brandKitItem {
  display: flex;
  padding: 0 25px 25px 0;
  margin-bottom: 40px;
  border-bottom: 1px solid var(--silver);

  @include media-md {
    flex-direction: column;
    padding-right: 0;
  }

  @include media-smm {
    margin-bottom: 24px;
  }
}

.brandKitItemLeft {
  flex: 1;
  max-width: 100%;
}

.brandKitItemRight {
  flex: 0 0 auto;
  max-width: 100%;
  margin-left: 25px;

  @include media-md {
    margin-top: 25px;
    margin-left: 0;
  }

  @include media-smm {
    margin-bottom: 0;
  }
}

.brandKitItemElem {
  height: 100%;
}

.brandKitItemElemRow {
  display: flex;
  align-items: center;

  @include media-smm {
    flex-direction: column;
  }
}

.brandKitItemTitle {
  margin-bottom: 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  color: var(--black);
}

.brandKitItemText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: var(--scorpion);
}

.brandKitItemElemVideo {
  margin-right: 15px;
  background: var(--gallery);

  @include media-smm {
    margin-right: 0;
    margin-bottom: 15px;
  }

  video {
    cursor: pointer;
  }
}

.brandKitItemElemVideo {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 224px;
  width: 224px;
  max-height: 126px;
  height: 126px;
  overflow: hidden;

  @include media-smm {
    max-width: 100%;
    width: 100%;
    height: auto;
    max-height: none;
    aspect-ratio: 16/9;
  }

  video,
  img {
    width: 100%;
    height: 100%;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
