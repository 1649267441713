@import '../../../src/assets/styles/typography.scss';

video {
  width: 100%;
}

.box {
  line-height: 42px;
  border-left: 1px solid var(--port-gore);
  padding-left: 8px;
}

.playWrapper {
  display: flex;
  align-items: center;
  border: 1px solid var(--port-gore);
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: opacity 0.2s;

  & .playButton {
    bottom: 100px;
    width: 24px;
    height: 24px;
    margin-right: 10px;
    background-image: url(../../assets/images/play.svg);
    background-size: 24px 24px;
    background-position: center center;
    background-repeat: no-repeat;
    flex-shrink: 0;

    &.isPlaying {
      background-image: url(../../assets/images/pause.svg);
      background-size: 20px 20px;
    }
  }
}

.playButtonText {
  font-weight: 700;
}

.pathContainer {
  position: relative;
  height: 48px;
  width: 88px;
  padding: 0;

  & video {
    width: 100%;
    height: 100%;
  }

  & img {
    width: 100%;
  }
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  margin-top: 28px;
}

.addClipButton {
  @include transparentButton;
  padding: 10px 19px;
  border-radius: 8px;
  font-size: 14px;

  @include media-smm {
    display: none;
  }
}

.addClipButtonSmall {
  display: none;

  @include media-smm {
    display: block;
    padding: 13px;
  }
}

.progressBarWrapper {
  position: relative;
  height: 36px;
  width: calc(100% - 260px);
  margin: 0 10px;

  @include media-smm {
    width: 240px;
  }
}

.progressBarContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
}

.progressSegment {
  position: absolute;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
  border: 2px solid var(--port-gore);
}

.progressBar {
  position: absolute;
  border-radius: 4px;
  background-color: var(--dodger-blue);
  opacity: 0.3;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  pointer-events: none;
}

.progressBarLine {
  position: absolute;
  height: 2px;
  width: 100%;
  background-color: var(--dodger-blue);
  opacity: 0.2;
  top: calc(50% - 1px);
  z-index: 1;
}

.grabber {
  position: absolute;
  top: calc(50% - 10px);
  width: 6px;
  height: 21px;
  background-color: var(--port-gore);
  opacity: 0.8;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &::after {
    content: '';
    position: absolute;
    width: 2px;
    height: 6px;
    left: calc(50% - 1px);
    top: calc(50% - 3px);
    background-color: var(--white);
  }

  &:active {
    opacity: 1;
  }

  &:active .grabberTimeContainer {
    display: flex;
  }

  &:before {
    content: '';
    width: 2px;
    height: 40px;
    background: var(--port-gore);
    position: absolute;
  }
}

.grabberStart {
  border-radius: 2px 0 0 2px;
  transform-origin: 100%;

  &:before {
    right: 0;
  }
}

.grabberEnd {
  border-radius: 0 2px 2px 0;

  &:before {
    left: 0;
  }
}

.grabberTimeContainer {
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 24px;
  left: calc(50% - 40px);
  top: 40px;
  background-color: var(--dodger-blue);
  border-radius: 4px;
  @include tiny;
  color: var(--white);
}

.videoActionsToggle {
  display: flex;
  align-items: center;
  font-weight: 700;

  img {
    padding-left: 11px;
  }
}

.videoActionsContainer {
  margin-top: 24px;

  .videoActionsShareDownload {
    @include flexSpaceBetweenAlignCenter;

    .enhanceVideoToggle {
      @include flexSpaceBetweenAlignCenter;
      font-weight: 700;

      div {
        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .downloadButton,
    .shareButton {
      @include button;
      border-radius: 30px;
      padding: 8px 33px;
      font-size: 12px;
    }

    .videoActionsButtons {
      @include flexSpaceBetweenAlignCenter;

      .shareButton {
        @include transparentButton;
        margin-right: 10px;
      }
    }
  }
}

.videoDurationContainer {
  display: flex;
  align-items: center;
  font-weight: 700;

  img {
    padding-left: 11px;
  }
}

.videoActionsContainer {
  margin-top: 24px;

  .videoActionsShareDownload {
    @include flexSpaceBetweenAlignCenter;

    .videoActionsButtons {
      @include flexSpaceBetweenAlignCenter;

      @include media-smm {
        margin-top: 24px;
        width: 100%;
      }

      .downloadButton,
      .shareButton {
        @include button;
        border-radius: 30px;
        padding: 8px 33px;

        @include media-smm {
          padding: 10px 12px;
          width: 45%;
          display: flex;
          justify-content: center;
          border-radius: 8px;
          font-size: 14px;
        }
      }

      .shareButton {
        @include transparentButton;
        margin-right: 10px;
      }
    }
  }
}

.buttonsContainer {
  margin-top: 25px;
  font-size: 14px;

  @include flexSpaceBetweenAlignCenter;
  flex-wrap: wrap;

  .firstButtonGroup,
  .secondButtonGroup {
    gap: 0 16px;
    @include flexSpaceBetweenAlignCenter;
    flex-wrap: wrap;
    border-radius: 8px;
    height: 52px;

    button {
      @include transparentButton;

      &.cancelButton {
        border: 0;
      }

      &.saveChangesButton {
        margin-left: 8px;
        line-height: 42px;
        padding: 0 16px;
        border-radius: 8px;

        @include media-smm {
          @include button;
        }
      }
    }

    img {
      margin: 8px;
      @include media-smm {
        margin: 0 !important;
        width: 100%;
        border: none;
        height: auto;
        padding: 0;
      }

      @include media-md {
        margin: 16px 10px 0 16px;
      }

      img {
        margin: 5px;
      }
    }
  }

  &.saving {
    button {
      opacity: 0.6;
    }
  }
}
