@import 'assets/styles/typography';
@import 'assets/styles/colors';

:root {
  --header-height: 72px;
}

* {
  box-sizing: border-box;
}

body {
  font-family: var(--primary-font);
  color: var(--mine-shaft);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.active {
  font-weight: 700;
  background-color: var(--catskill-white);
}

section {
  padding: 104px 0;
  @include media-sm {
    padding: 32px 0;
  }
}

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
}

h4 {
  @include h4;
}

h5 {
  @include h5;
}

p {
  @include p;
}

ol {
  @include ol;
}

ul {
  @include ul;
}

li {
  @include li;
}

a {
  @include a;
}

#font-picker button,
ul {
  background-color: white !important;
}
