@import '../../assets/styles/sizes.scss';
@import '../../assets/styles/typography';
@import '../../assets/styles/colors.scss';

.disabledAll {
  pointer-events: none;
  opacity: 0.4;
}

.button {
  @include button;
  padding: 8px 16px;

  &:hover {
    opacity: 0.92;
  }

  & .backIcon {
    background-color: var(--white);
    border-radius: 50%;
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    margin-right: 8px;
    padding: 4px;
    transform: rotate(180deg);

    & img {
      width: 12px;
      height: 12px;
    }
  }
}

.downloadIcon {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.videoWrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(175px, 1fr));
  gap: 16px;
  margin-top: 18px;

  @include media-sm {
    display: flex;
    flex-direction: column;
  }
}

.box {
  width: 100%;
  position: relative;

  @include media-sm {
    min-height: 63vw;
  }
}

.projectDetailInner {
  width: 100%;
  @include media-smm {
    width: 100%;
  }

  .routePaths {
    display: flex;
    align-items: center;

    .leftSlack {
      margin: 0 16px;
    }

    .currentProjectName {
      cursor: pointer;
    }
  }

  .projectDetailHeader {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .projectReviewAndApprove {
    display: flex;
    justify-content: space-between;

    .reviewButton {
      @include button;
      @include transparentButton;
      padding: 10px 12px;
      border-radius: 8px;
      margin-right: 10px;
    }

    @include media-smm {
      display: none;
    }
  }

  .projectDetailHeader {
    margin-top: 20px;
    @include flexSpaceBetweenAlignCenter;

    @include media-sm {
      margin-top: 10px;
    }
  }

  .projectReviewAndApprove {
    @include flexSpaceBetweenAlignCenter;

    @include media-smm {
      width: 100%;
      margin-top: 16px;
    }

    .reviewButton,
    .approveButton {
      @include button;
      padding: 10px 12px;
      border-radius: 8px;

      @include media-smm {
        width: 45%;
        display: flex;
        justify-content: center;
      }
    }

    .reviewButton {
      @include transparentButton;
      margin-right: 10px;
    }
  }
}

.boxHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.actions {
  margin-top: 10px;
}

.sbCover {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  user-select: none;
  display: flex;
  background-color: #fff;
}

.leftSide {
  width: 333px;
  height: 100%;
  overflow-x: hidden;
  position: relative;
  background-color: #eee;
}

.resizer {
  width: 18px;
  top: 0;
  right: 0;
  cursor: col-resize;
  height: 100%;
  position: absolute;
  // background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-left: 5px;
}

.resizerButton {
  width: 20px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  border: 2px solid #666666;
}

.resizerIcon {
  width: 16px;
  height: 16px;
}

.leftSideHeader {
  padding: 22px 11px;
  background-color: #fff;
  @include media-sm {
    padding: 0;
  }
}

.rightSide {
  flex-grow: 1;
  text-align: center;
}

.projectsSectionHeading {
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 0;

  @include media-smm {
    font-size: 14px;
  }
}

.resizingDropDown {
  width: 80%;
  @include media-sm {
    width: 100%;
  }
}

.resizingOptions {
  height: 40px;
}

.resizingOption {
  display: flex;
  align-items: center;
  color: var(--port-gore);

  img {
    padding-right: 10px;
  }

  p {
    color: var(--gray);
    padding-left: 5px;
  }
}

.projectsFunctionHeading {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.titleField {
  width: 100%;
}

.listItem {
  border: 1px solid var(--gray);
}

.projectsFunctionHeading {
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.stylingFontIcons {
  // border: 1px solid var(--light-gray);
  // border-radius: 5px;
  cursor: pointer;
  :hover {
    background-color: #e0e0e0;
  }
}

.textFormatting {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    border: 1px solid var(--light-gray);
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 5px;
  }
}

.uploadLogoIcon {
  background-color: var(--light-gray);
  color: var(--white);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .uploadLogoIconMain {
    width: 120px;
    height: 50px;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      image-rendering: pixelated;
      padding-top: 5px;
      cursor: pointer;
      // object-fit: contain;
    }
  }

  p {
    padding: 5px 0;
    color: var(--white);
    // font-size: 14px;
    cursor: pointer;
  }
}

.uploadedLogoIcon {
  width: 65px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    height: 50px;
    padding-top: 5px;
    cursor: pointer;
  }

  p {
    padding: 5px 0;
  }
}

.logoOptions {
  display: flex;
  align-items: center;
  color: var(--port-gore);
}

.fontStyling {
  display: flex;
  gap: 20px;
  :hover {
    background-color: #e0e0e0;
  }
}

.alignmentStyling {
  display: flex;
  gap: 25px;
}

.timeCodeWrapper {
  padding: 3px;
}

.timeCode {
  cursor: pointer;
  padding: 1rem;
  border-radius: 0.3rem;
  height: 40px;
  border: solid 1px var(--silver);
  text-align: center;
  width: -webkit-fill-available;
}

.invalidTimeCode {
  border: solid 1px var(--alizarin-crimson);
}
