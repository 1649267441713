@import '../../assets/styles/sizes.scss';

.header {
  position: relative;
  // display: flex;
  align-items: center;
  justify-content: center;
  // padding: 8px 32px;

  @include media-sm {
    justify-content: space-between;
    background-color: var(--alizarin-crimson);
    border: 0;
    padding: 8px 16px;
    z-index: 3;
  }

  .trialInfoBar {
    width: 100%;
    height: auto;
    text-align: center;
    background-color: #d2d4ee;

    @include media-sm {
      display: none;
    }

    .trialInfo {
      display: inline-block;
      width: 90%;
      padding: 3px 0px;
      font-size: 18px;
      line-height: 24.3px;

      .bold {
        font-weight: 700;
      }

      .upgradeLink {
        color: var(--alizarin-crimson);
        font-weight: 700;
      }
    }

    .closeIcon {
      float: right;
      margin-top: 4px;
      margin-right: 10px;
      cursor: pointer;
    }
  }

  .uniqueIdBar {
    position: relative;
    width: 100%;
    height: 56px;
    padding: 24px 28px 24px 24px;
    box-shadow: 0px 2px 12px 0px #0000001a;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: right;

    @include media-sm {
      display: none;
    }

    .uniqueId {
      font-weight: 700;
      line-height: 16.1px;
      color: var(--alizarin-crimson);
    }
  }
}
